<template>
  <header class="header">
    <div class="_container">
      <div class="header__container">
        <div class="header__head">
          <div
            class="header__profile-avatar"
            @click="$router.push('/dashboard/fortune-wheel')"
          >
            <img :src="user?.avatar" alt="avatar" />
          </div>
          <div class="header__profiles">
            <div @click="$router.push('/dashboard/replenishment-withdrawal/deposit')" class="header__profile">
              <img class="header__profile-icon" src="@/assets/svg/USDT-icon.svg" alt="">
              <div class="header__profile-info">
                <span>{{ wallet[0]?.balance }}</span>
              </div>
            </div>
            <CLOTWindow />
          </div>
          <div class="header__draft"></div>
        </div>
<!--        <div class="header__winner">-->
<!--          <WinnerBlock v-for="winner in lastRoomsWinners" :winner="winner" />-->
<!--        </div>-->
      </div>
    </div>
    <div class="header__bg">
      <div class="header__bg-circle header__bg-circle--one"></div>
      <div class="header__bg-circle header__bg-circle--two"></div>
      <div class="header__bg-circle header__bg-circle--three"></div>
      <div class="header__bg-circle header__bg-circle--four"></div>
      <div class="header__bg-blur"></div>
    </div>
  </header>
</template>

<script>
import WinnerBlock from "@/components/WinnerBlock/WinnerBlock.vue";
import { mapState } from "vuex";
import Button from "@/components/UI/Button/Button.vue";
import Logo from "@/components/Logo/Logo.vue";
import router from "@/router";
import LanguageSwitcher from "@/components/LanguageSwitcher/LanguageSwitcher.vue";
import CLOTWindow from "@/components/CLOTWindow/CLOTWindow.vue";

export default {
  props: {
    isBurger: {},
  },
  data() {
    return {
      count: 5,
    };
  },
  methods: {
    router() {
      return router;
    },
  },
  mounted() {
    this.$store.dispatch("rooms/getLastWinners", { count: this.count });
    this.$store.dispatch("wallet/getWallet");
  },
  computed: {
    ...mapState("rooms", ["lastRoomsWinners"]),
    ...mapState("wallet", ["wallet"]),
    ...mapState("auth", ["user"]),
  },
  components: {
    Logo,
    Button,
    WinnerBlock,
    LanguageSwitcher,
    CLOTWindow
  },
};
</script>

<style lang="scss" scoped>
@import "dashoardHeader";
</style>
