import * as API from '../../api/index'

export default {
  namespaced: true,
  state() {
    return {
      error: null,
      success: null,
      successModal: null,
      paymentModal: null,
      notification: null,
    }
  },
  actions: {
    showNotification({ commit }, notification) {
      commit('SHOW_NOTIFICATION', notification)
      setTimeout(() => {
        commit('HIDE_NOTIFICATION')
      }, 5000)
    },
  },
  mutations: {
    SHOW_NOTIFICATION(state, notification) {
      state.notification = notification
    },
    HIDE_NOTIFICATION(state) {
      state.notification = null
    },
    ERROR_CHANGE(state, error) {
      state.error = error

      setTimeout(() => {
        state.error = null
      }, 5000)
    },
    SUCCESS_CHANGE(state, success) {
      state.success = success

      setTimeout(() => {
        state.success = null
      }, 5000)
    },
    SUCCESS_MODAL(state, buy) {
      state.successModal = buy
    },
    PAYMENT_MODAL(state, data) {
      state.paymentModal = data
    },
    REMOVE_MODAL(state) {
      state.successModal = null
      state.paymentModal = null
    }
  },
  getters: {

  },
}
