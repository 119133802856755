import * as API from '../../api/index'
import router from "@/router";

export default {
  namespaced: true,
  state() {
    return {
      ticketUserRoom: 0
    }
  },
  actions: {
    buyTicket({ state, commit, dispatch }, { id, count, routerParams }) {
      API.buyTicket({ id, count }).then(response => {
        if (response) {
          dispatch('notifications/showNotification', { type: 'success', text: 'Вы успешно купили билет!' }, { root: true })
          dispatch('getTicketUserRoom', id)
          dispatch('wallet/getWallet', null, { root: true })

          if (routerParams?.id) {
            dispatch('rooms/getRoom', +routerParams?.id, { root: true })
          } else {
            dispatch('rooms/getActiveRoom', null, { root: true })
          }

          router.push(`/dashboard/lottery/${id}`)
        }
      })
    },
    getTicketUserRoom({ state }, id) {
      API.getTicketUserRoom(id).then(response => {
        state.ticketUserRoom = response
      })
    }
  },
  mutations: {

  },
  getters: {

  },
}
