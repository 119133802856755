import * as API from '../../api/index'

export default {
  namespaced: true,
  state() {
    return {
      secretKey: null,
    }
  },
  actions: {
    changeAvatar({ state, commit }, avatar) {
      API.changeAvatar(avatar).then(response => {
        const user = response?.user

        if (user) {
          commit('auth/SET_USER', user, { root: true })
        }

        if (response) {
          commit('notifications/SUCCESS_CHANGE', 'Вы успешно сменили аватар!', { root: true })
        }
      })
    },
    changeUsername({ state, commit }, username) {
      API.changeUsername(username).then(response => {
        const user = response?.user

        if (user) {
          commit('auth/SET_USER', user, { root: true })
        }

        if (response) {
          commit('notifications/SUCCESS_CHANGE', 'Вы успешно сменили имя!', { root: true })
        }
      })
    },
    changeEmail({ state, commit }, { email, component }) {
      API.changeEmail(email).then(response => {
        const user = response?.user

        if (user) {
          commit('auth/SET_USER', user, { root: true })
        }

        if (response) {
          commit('notifications/SUCCESS_CHANGE', 'Вы успешно сменили Email!', { root: true })
          component.closeModal()
        }
      })
    },
    changePassword({ state, commit }, { password, component }) {
      API.changePassword(password).then(response => {
        if (response) {
          commit('notifications/SUCCESS_CHANGE', 'Вы успешно сменили пароль!', { root: true })
          component.closeModal()
        }
      })
    },
    secretKey2FA({ state }) {
      API.secretKey2FA().then(response => {
        state.secretKey = response
      })
    },
    enable2FA({ state, commit }, { data, component }) {
      API.enable2FA(data).then(response => {
        const user = response?.user

        if (user) {
          commit('auth/SET_USER', user, { root: true })
        }

        if (response) {
          commit('notifications/SUCCESS_CHANGE', 'Вы успешно подключили двухфакторную аутентификацию!', { root: true })
          component.closeModal()
        }
      })
    },
    disable2FA({ state, commit }, { data, component }) {
      API.disable2FA(data).then(response => {
        const user = response?.user

        if (user) {
          commit('auth/SET_USER', user, { root: true })
        }

        if(response) {
          commit('notifications/SUCCESS_CHANGE', 'Вы успешно отключили двухфакторную аутентификацию!', { root: true })
          component.closeModal()
        }
      })
    }
  },
  mutations: {

  },
  getters: {

  },
}
