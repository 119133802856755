<template>
  <div
    class="new-notify"
    :class="[{ 'new-notify__open': notification?.type }, notification?.type]"
  >
    <img v-if="notification?.type === 'success'" src="@/assets/svg/Success_icon-white.svg" alt="">
    <p>{{ notification?.text || 'Успех' }}</p>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState('notifications', ['notification']),
  },
  methods: {
    ...mapMutations('notifications', ['HIDE_NOTIFICATION']),
  },
};
</script>

<style lang="scss" scoped>
.new-notify {
  display: flex;
  align-items: center;
  gap: 12px;
  width: calc(100% - 16px);
  height: fit-content;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #fff;
  box-shadow: 0 16px 24px 0 #00000029;
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 99999;
  transform: translateX(calc(100% + 16px));
  transition: all 0.3s;
  background-color: #4caf50;

  &__open {
    transform: translateX(0);
    transition: all 0.3s;
  }
}

.new-notify.success {
  background-color: #4caf50;
}

.new-notify.error {
  background-color: #f44336;
}
</style>