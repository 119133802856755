<template>
  <div class="operations-history">
    <div>
      <div class="operations-history__wrapper">
        <h2 class="operations-history__title">{{getTranslation('Мои операции')}}</h2>
        <div class="operations-history__filter">
          <div class="operations-history__filter-select">
            <Select
                :dataSelect="selectOperations"
                @change-type="changeType"
            />
          </div>
          <div class="operations-history__filter-time">
            <TimeTabs
                :tabs="timeTabs"
                @tab-selected="tabSelected"
            />
          </div>
        </div>
        <div class="operations-history__container">
          <OperationBlock
              v-if="allTransaction"
              v-for="transaction in allTransaction"
              :transaction="transaction"
          />
          <EmptyBlock v-if="allTransaction?.length === 0">
            You have not transactions yet
          </EmptyBlock>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/UI/Select/Select.vue";
import TimeTabs from "@/components/UI/TimeTabs/TimeTabs.vue";
import OperationBlock from "@/components/OperationBlock/OperationBlock.vue";
import {mapState ,mapGetters} from "vuex";
import {login} from "@/api";
import EmptyBlock from "@/components/EmptyBlock/EmptyBlock.vue";
export default {
  data() {
    return {
      selectOperations: [
        { name: 'Все', value: 'all', id: 0 },
        { name: 'Пополнения', value: 'deposits', id: 1 },
        { name: 'Вывод', value: 'withdraw', id: 2 },
        { name: 'Покупки', value: 'purchases', id: 3 },
        { name: 'Бонусы', value: 'bonuses', id: 4 },
      ],
      timeTabs: [
        { value: 7, isTab: false, id: 0 },
        { value: 30, isTab: false, id: 1 },
      ],
      paymentsData: {
        type: 'all',
        days_ago: null,
      },
    }
  },
  methods: {
    changeType(value) {
      this.paymentsData.type = value

      if (this.paymentsData.type === null) {
        this.$store.dispatch('payment/getAllTransaction')
      } else {
        this.fetchData()
      }
    },
    tabSelected(value) {
      this.paymentsData.days_ago = value
      this.fetchData()
    },
    fetchData() {
      this.$store.dispatch('payment/getPaymentsHistory', this.paymentsData)
    }
  },
  mounted() {
    // this.$store.dispatch('payment/getPaymentsTypes')
    this.fetchData()

    this.timer = setInterval(() => {
      this.fetchData()
    }, 10000)
  },
  computed: {
    ...mapState('payment', ['allTransaction']),
    ...mapGetters('locale', ['getTranslation'])
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  components: {
    EmptyBlock,
    OperationBlock,
    TimeTabs,
    Select,
  }
}
</script>

<style lang="scss" scoped>
@import "operationsHistory";
</style>